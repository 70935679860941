import './App.css';
import React, { useState } from 'react';

function App() {

  const [darkMode, setDarkMode] = useState(false);

  const toggleDarkMode = () => {
    setDarkMode(!darkMode);
  }

  return (
    <div className={`App ${darkMode ? 'dark-mode' : ''}`}>
    <header>
    <nav>
  <table style={{ width: "100%" }}>
    <tr>
        <ul>
          <li><a href="#home">Home</a></li>
          <li><a href="#about">Über mich</a></li>
          <li><a href="#projects">Projekte</a></li>
          <li><a href="#contact">Kontakt</a></li>
        </ul>
        <button id="darkModeToggle" onClick={toggleDarkMode}>Dark Mode</button>
    </tr>
  </table>
</nav>

    </header>

    <main>
        <section id="home">
            <h1>Willkommen auf meiner Website!</h1>
            <p>Hier können Sie mehr über meine Arbeit und Projekte erfahren.</p>
        </section>

        <section id="about">
            <h2>Über mich</h2>
            <p>Ein paar Worte über mich und meine Erfahrungen ...</p>
        </section>

        <section id="projects">
            <h2>Meine Projekte</h2>
            <p>Informationen zu meinen bisherigen Projekten ...</p>
        </section>

        <section id="contact">
            <h2>Kontaktieren Sie mich</h2>
            <p>Wenn Sie Fragen haben, zögern Sie nicht, mich zu kontaktieren ...</p>
        </section>
    </main>

    <footer>
        <p>&copy; 2023 Philipp Blum. Alle Rechte vorbehalten.</p>
    </footer>
    </div>
  );
}

export default App;
